import React, { memo } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from '../../components/Shared'
import IntegrationsTemplate from '../../components/Integrations'
import helmetProps from '../../components/Integrations/Rhino/helmetProps'
import { sanitizedCopy, sanitizedExampleFeatureData } from './utils'

export const query = graphql`
  {
    irisLogo: file(
      relativeDirectory: { eq: "integrations" }
      name: { eq: "1_IrisLogo" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 55) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    rhinoIcon: file(
      relativeDirectory: { eq: "integrations/Rhino" }
      name: { eq: "1_RhinoLogo" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 55) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    rhinoExportModel: file(
      relativeDirectory: { eq: "integrations/Rhino" }
      name: { eq: "1.1" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 1094, maxHeight: 804, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    rhinoVRIdea: file(
      relativeDirectory: { eq: "integrations/Rhino" }
      name: { eq: "2" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 514, maxHeight: 338, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    rhinoNamedViews: file(
      relativeDirectory: { eq: "integrations/Rhino" }
      name: { eq: "3.1" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 960, maxHeight: 560, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    rhinoMaterials: file(
      relativeDirectory: { eq: "integrations/Rhino" }
      name: { eq: "3.2" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 960, maxHeight: 560, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    rhinoGeolocation: file(
      relativeDirectory: { eq: "integrations/Rhino" }
      name: { eq: "3.3" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 960, maxHeight: 560, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    rhinoLayers: file(
      relativeDirectory: { eq: "integrations/Rhino" }
      name: { eq: "3.4" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 960, maxHeight: 560, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    rhinoPlugin: file(
      relativeDirectory: { eq: "integrations/Rhino" }
      name: { eq: "3.5" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 1006, maxHeight: 592, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default memo(props => {
  const data = props.data
  const images = {
    logos: {
      iris: data.irisLogo,
      integrationCompanyIcon: data.rhinoIcon,
    },
    ideaMockups: {
      exportModel: data.rhinoExportModel,
      vrIdea: data.rhinoVRIdea,
    },
  }

  const header = `Communicate complexity with a single click`
  const subHeader = `The Prospect plugin for Rhino`
  const integrationExplanationHeader = `Drive collaboration & understanding`
  const integrationExplanation = `Curate immersive walkthroughs to present design options to clients and stakeholders. Bring everyone onto the same page with VR—go beyond 2D drawings that leave room for understanding.`
  const sectionLinksHeader = `Prospect directly imports Rhino files into VR at the click of a button, with:`
  const copy = {
    header,
    subHeader,
    integrationExplanationHeader,
    integrationExplanation,
    sectionLinksHeader,
  }

  const exampleFeatureData = {
    views: {
      image: data.rhinoNamedViews,
      title: 'Named views',
      description: `Named Views in Rhino convert into Viewpoints in Prospect. Focus on the most important parts of the model in VR with immersive, Viewpoint-driven walkthroughs.`,
    },
    materials: {
      image: data.rhinoMaterials,
      title: 'Materials',
      description: `All Rhino materials render in VR with Prospect. View your Rhino model the way it was meant to be seen—in an immersive, true-to-scale setting, with all materials retained.`,
    },
    geolocation: {
      image: data.rhinoGeolocation,
      title: 'Geolocation and sun studies',
      description: `Prospect brings your Rhino file's geolocation data into VR. Use the Sun Settings menu to perform accurate sun and shadow studies.`,
    },
    layers: {
      image: data.rhinoLayers,
      title: 'Layers',
      description: `The plugin exports objects in layers that are set to 'off,' but initially hides them in VR. Toggle these layers on and off in Prospect to showcase different color and material options—or different design options entirely.`,
    },
    plugin: {
      image: data.rhinoPlugin,
      title: 'One-click native plugin',
      description: `Our native Rhino plugin makes it easy to get started with immersive walkthroughs—just click 'View in VR.' What you see in your 3D view is exactly what you see in VR.`,
    },
  }

  return (
    <>
      <Helmet {...helmetProps} />
      <IntegrationsTemplate
        images={images}
        copy={sanitizedCopy(copy)}
        exampleFeatureData={sanitizedExampleFeatureData(exampleFeatureData)}
      />
    </>
  )
})
